<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form#FormProjectEdit(:endpoint='`/_/Projects/${projectId}`' @submitSuccess='onSubmitSuccess')
    template(#default='{ loading }')
      form-header
        span(v-html='$t("Projekt bearbeiten")')
      form-input(name='Name' :placeholder='$t("i.e. My New Project")' :autofocus='true')
      form-input-money(name='Budget' :markOptional='true') €
      form-input-date(name='Deadline' :markOptional='true')
      form-textarea(name='Description' :markOptional='true' :label='$t("Description", "Projects")')
      form-footer
        form-action(name='UpdateSettings' :primary='true' :loading='loading' class='font-bold' :label='$t("Speichern")')
</template>

<script setup lang="ts">
import { useProjectsStore } from '../store'
import { type IProjectData } from '../types'
import { type IFormResponseInfo } from '@/components/GenericForm/types'

defineProps<{ projectId: string }>()
const emit = defineEmits(['close'])
const store = useProjectsStore()
const onSubmitSuccess = (e: IFormResponseInfo<IProjectData>) => {
  store.setProject(e.data)
  emit('close')
}
</script>
